"use client";

import { Button, Typography, Box } from "@mui/material";
import Link from "next/link";

export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset?: () => void;
}) {
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography fontWeight="bold" variant="h5">
        There was a problem! {`${error.name}`}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        Please try again later or contact support if the problem persists.
      </Typography>
      <Button onClick={() => reset()}>Try again</Button>
      <Link href="/">Return Home</Link>
    </Box>
  );
}
